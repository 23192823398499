import { createClient } from 'webdav';

const getCloudApi = ({ cloudUsername, cloudPassword }) =>
    createClient(process.env.REACT_APP_CLOUD_ROOT, {
        username: cloudUsername,
        password: cloudPassword,
    });

export const getAttachmentRootDirectory = () => `PzH/Anhänge`;

export const getCustomerRootDirectory = customer =>
    `PzH/Kunden/${customer.id}_${customer.displayContact.lastname} ${customer.displayContact.firstname}`;

export default getCloudApi;
